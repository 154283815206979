<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 18.59 18.59">
    <circle id="Ellipse_10" data-name="Ellipse 10" cx="9.295" cy="9.295" r="9.295" fill="#267c26" />
    <path
      :style="iconStyle"
      d="M3.059-.158.123-3.076A.428.428,0,0,1,0-3.393a.428.428,0,0,1,.123-.316l.65-.633a.392.392,0,0,1,.308-.141.447.447,0,0,1,.325.141L3.375-2.373,7.594-6.592a.447.447,0,0,1,.325-.141.392.392,0,0,1,.308.141l.65.633A.428.428,0,0,1,9-5.643a.428.428,0,0,1-.123.316L3.691-.158a.4.4,0,0,1-.316.141A.4.4,0,0,1,3.059-.158Z"
      transform="translate(4.775 12.775)"
      fill="#fff"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '17.59',
    },
    width: {
      type: String,
      default: '17.59',
    },
    color: {
      type: String,
      default: 'primary-green',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
